/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/layout";
import PropertyTeaser from "../components/entities/property/propertyTeaser";
import { Link } from "gatsby";
import Container from "../components/container";
import { graphql } from "gatsby";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { PortableText } from "@portabletext/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
const PropertyIndex = (props) => {
  const bgImage = convertToBgImage(
    props.data.heroImage.childImageSharp.gatsbyImageData
  );
  return (
    <Layout type="story-index-outer">
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <Container>
          <div
            sx={{
              position: "relative",
              zIndex: "2",
              padding: "180px 0px 100px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 sx={{ color: "white" }}>
              {props.data.generalArea.nodes[0].headline} Properties
            </h1>
          </div>
        </Container>
        <div
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            opacity: "0.7",
            background:
              "linear-gradient(to top, transparent 0%, #000000 40%, #000000 100%)",
          }}
        ></div>
      </BackgroundImage>
      <Container className="article-index-container">
        <div
          sx={{
            padding: "0px 0px 0px 0px",
          }}
        >
          <ul
            sx={{
              padding: "80px 0px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {props.data.property.nodes.map((property, index) => (
              <li
                key={index}
                sx={{
                  width: [
                    "100%",
                    "calc(100% / 2 - 25px)",
                    "calc(100% / 3 - 25px)",
                  ],
                  marginRight: ["0px", "25px", "25px"],
                  marginBottom: "20px",
                  display: "flex",
                  "&:nth-child(3n)": {
                    marginRight: ["0px", "25px", "0px"],
                  },
                  "&:nth-child(2n)": {
                    marginRight: ["0px", "0px", "25px"],
                  },
                }}
              >
                <PropertyTeaser entity={property}></PropertyTeaser>
              </li>
            ))}
          </ul>
        </div>
        <h3
                              sx={{
                                maxWidth:"600px",
                              }}
                              >
              We work with hundreds of properties in St. Louis, the surrounding areas, and the entire Midwest Region - too many to list here. <Link to="/get-started">Click here</Link> for a free quote
              </h3>
      </Container>
    </Layout>
  );
};

export default PropertyIndex;

export const articleIndexQuery = graphql`
  query propertyIndexQuery($slug: String!) {
    heroImage: file(name: { eq: "cityclub" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    generalArea: allSanityGeneralArea(
      filter: { slug: { current: { eq: $slug } } }
    ) {
      nodes {
        headline
        slug {
          current
        }
      }
    }
    property: allSanityProperty(
      filter: {
        neighborhood: { generalArea: { slug: { current: { eq: $slug } } } }
      }
    ) {
      nodes {
        address
        headline
        _rawMainImage(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
      }
    }
  }
`;
